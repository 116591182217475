const phoneValidationStatus = JSON.parse(localStorage.getItem('phoneValidation')) || false;

if(phoneValidationStatus){
    document.addEventListener('DOMContentLoaded', function () {

        updatePizzaOverlay();

        const tomato = document.getElementById('bigbytes-tomato');
        let splatMode = false;
        let firstClick = false;

        const { splatStages,
            splatSound,
            tomatoSuccess
         } = window.bigbytesData; 

        tomato.style.visibility = 'visible';
        splatSound.volume = 0.1;
        tomatoSuccess.volume = 0.2;

        tomato.addEventListener('click', function () {
            splatMode = true;
            tomato.style.pointerEvents = 'none';
            tomatoSuccess.play();
            window.addEventListener('click', createSplat);
        });

        let splatsArray = []; 

        function createSplat(event) {
            if (!splatMode || !firstClick){
                firstClick = true;
                return;
            }
            const pizzaContainer = document.getElementById('splatHolder');
            if (!pizzaContainer) return;

            const containerRect = pizzaContainer.getBoundingClientRect();
        
            // For ultrawide
            const clickX = event.pageX - containerRect.left; 
            
            const isInsideContainer = 
                event.pageX >= containerRect.left &&
                event.pageX <= containerRect.right &&
                event.pageY >= pizzaContainer.offsetTop &&
                event.pageY <= (pizzaContainer.offsetTop + pizzaContainer.offsetHeight);

            if (!isInsideContainer) return;

            if (splatsArray.length >= 10) {
                const splatToRemove = splatsArray.shift();
                splatToRemove.classList.add('splat-is-fadeout'); 

                setTimeout(() => {
                    pizzaContainer.removeChild(splatToRemove);
                }, 1000); 
            }

            const splat = document.createElement('img');
            splat.className = 'splat';
            splat.src = splatStages[0]; 
            pizzaContainer.appendChild(splat);
            splatsArray.push(splat);

            const targetX = `${clickX- 125}px`;
            const targetY = `${event.pageY - 125}px`;


            splat.style.left = targetX;
            splat.style.top = targetY;


            setTimeout(() => {

                const randomPairKey = `pair${Math.ceil(Math.random() * 3)}`;
                const [thirdImage, fourthImage] = splatStages[2][randomPairKey];

                const imageSequence = [
                    splatStages[1], 
                    thirdImage,   
                    fourthImage    
                ];

                imageSequence.forEach((src, index) => {
                    setTimeout(() => {
                        splat.src = src;
                        if (index === imageSequence.length - 1) {
                            splat.classList.add('splat-zindex-3');
                        }else if (index === imageSequence.length - 3){
                            splatSound.currentTime = 0;
                            splatSound.play();
                        }
                    }, index * 150); 
                });
            }, 200); 
        }
        
        



        function updatePizzaOverlay() {
            const pizzaSequence = document.querySelector('.pizza-sequence');
            const pizzaSlice = document.querySelector('.pizza-slice');

            if (window.innerWidth <= 1920) {
                const pizzaRect = pizzaSlice.getBoundingClientRect();

                pizzaSequence.style.left = `${pizzaRect.left}px`;

                pizzaSequence.style.visibility = 'visible';
            }else{
                pizzaSequence.style.visibility = 'visible';
                pizzaSequence.style.left = '1042.98px';
            }
        }
        updatePizzaOverlay();

        window.addEventListener('resize', updatePizzaOverlay);
    });

}
